/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- VARIABLES (INITIALIZATION) ----------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */



/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- IMPORTS ------------------------------------ */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

import.meta.glob([
	'../images/**',
	'../fonts/**',
]);

import './bootstrap';
// import ImageProcessor from './skin-detect';

import $ from "jquery";
import { gsap } from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { SplitText } from 'gsap/SplitText';
import Splide from '@splidejs/splide';
import { _ } from 'lodash';
import * as faceapi from "face-api.js";
import Alpine from 'alpinejs';
window.Alpine = Alpine;

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- VARIABLES ---------------------------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

gsap.registerPlugin( ScrollSmoother, ScrollTrigger, ScrambleTextPlugin, ScrollToPlugin, SplitText );

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- GENERAL ------------------------------------ */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

( function( selector )
{
	'use strict'

    var scope = $( selector );
    if( !scope.length ) return;

    var colors = [ [ '#FAD6C0', '#E8C3A8', '#DCB58C', '#DCA475' ], [ '#DF9D7B', '#C6926A', '#9F664B', '#834432' ], [ '#6D3C2E', '#723A29', '#5C2E1F', '#773C2A' ] ];

    for (var i = 0; i < scope.length; i++)
    {
	    scope.eq( i ).append( "<div class='logo-palette'></div>" );

	    var grid = new Array();
	    for (var x = 0; x < 3; x++)
	    {
	    	grid.push( new Array() );
		    for (var y = 0; y < 4; y++)
		    {
		    	grid[ x ].push( new Array() );
		    	grid[ x ][ y ] = scope.eq( i ).find( '.logo-palette' ).append( "<div class='palette'></div>" ).children( '.palette' ).eq( ( x * 4 ) + y );
		    	grid[ x ][ y ].css( "background-color", colors[ x ][ y ] );
		    	gsap.to( grid[ x ][ y ], { opacity: "random( 0.1, 0.5, 0.05 )", duration: "random( 1, 2, 0.25 )", repeat: -1, yoyo: true } );
		    }
	    }
    }
    
})( '.logo-palette-holder', '.logo-palette-holder.sm' );



( function( selector )
{
	'use strict'

    var scope = $( selector );
    if( !scope.length ) return;

	$( document ).ready( function()
	{
	    scope.removeClass( 'loading' );
	});

    
})( 'body[data-section="home"], body[data-section="webapp"]' );

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- HOMEPAGE ----------------------------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

( function( selector )
{
	'use strict'

    var scope = $( selector );
    if( !scope.length ) return;

	window.addEventListener( 'resize', _.debounce(() =>
	{
	 	ScrollTrigger.refresh();
	}, 100 ));

	document.addEventListener( 'alpine:init', () => {
	    Alpine.data( 'modelOpen', () => ({
	        open: false,
	        image( url ) { this.$refs.image.src = url; },
	        toggle() { this.open = !this.open; },
			init() {
				this.$watch('open', () => {
					gsap.to( "#smooth-wrapper", { filter: "blur(" + (this.open ? '10px': '0px') + ")", ease: "power2.in", duration: 0.3 });
				})
			}
	    })),
	    Alpine.data( 'menuOpen', () => ({
	        open: false,
	        toggle() { this.open = !this.open; }
	    }));
	});

	window.modelOpen_open = function ( e ) { var ref = document.getElementById( 'modelOpen' )._x_dataStack[ 0 ]; ref.open = true; ref.image( e.getElementsByTagName( 'img' )[ 0 ].getAttribute('src') );  }
	window.modelOpen_close = function ( e ) { var ref = document.getElementById( 'modelOpen' )._x_dataStack[ 0 ]; ref.open = false; }

	Alpine.start();

	$( document ).ready( function()
	{

		ScrollTrigger.normalizeScroll( true );

		var mm = gsap.matchMedia();

		// mm.add( "(min-width: 768px)", () => {

			// create the scrollSmoother before your scrollTriggers
			let smoother = ScrollSmoother.create({
				smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
				effects: true, // looks for data-speed and data-lag attributes on elements
				// smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
	  			normalizeScroll: true
			});
		// });

		/* SETUP THE NAVIGATION MENU */
		( function( selector )
		{
			'use strict'

		    var scope = $( selector );
		    if( !scope.length ) return;

		    var $ele = $( scope ).find( '.nav-link' );
		    for (var i = 0; i < $ele.length; i++)
		    {
			    $ele.eq( i ).off( 'click' ).on( 'click', function( e ) {
			    	var $tgt = $( $( e.target ).data( 'nav' ) ).eq( 0 );
					gsap.to( window, { 
						duration: 1,
						scrollTo: $tgt.offset().top + 1,
						ease: "power2.out"
					} );
			    } );
		    }

	    	/* NAV TIMELINE */

		    var sections = scope.parents( '.menu-holder-mobile' ).find( '.nav-link' );
		    for( var i = 0; i < sections.length; i++ )
		    {
				var t_nav = gsap.timeline({
				    scrollTrigger: {
				        trigger: $( sections.eq( i ).data( 'nav' ) ),
				        start: 'top center', // when the top of the trigger hits the top of the viewport
				        end: 'bottom center', // end after scrolling 500px beyond the start
				        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
				        toggleClass: { targets: $( '.menu-holder .nav-link[ data-nav="' + sections.eq( i ).data( 'nav' ) + '" ]' ), className: "active" },
				        // markers: true,
				    }
				});
		    }
		    
		})( 'nav .menu-holder' );

    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */
    	/* SECTION 1 TIMELINE ------------------------------- */
    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */

		/* SETUP THE CAROUSEL */
		var splides_ele = $( '.carousel-hero .splide__list .splide__slide' );
    	for( var i = splides_ele.length - 1; i >= 0; i-- )
    	{
    		var info = $( splides_ele[ i ] ).children( '.img-holder' ).eq( 0 ).append( "<div class='img-info'><div class='title'><div class='header'>#268ToneProud</div><div class='sub-header'>CAPTURE YOUR UNIQUE TONE</div></div><div class='footer'><div class='name'></div>&nbsp;<div class='value'></div></div></div>" ).children( '.img-info' );
    			info.find( ".footer .name" ).html( info.parent().data( 'name' ) );
    			info.find( ".footer .value" ).html( info.parent().data( 'value' ) );
    			
    			var arr = info.siblings( "img" ).eq( 0 ).attr( "srcset" ).split( "," );
    			var tgt = new URL( arr[ 0 ].split( " " )[ 0 ] );
    			info.parent().css( "background-image", "url(" + tgt.href + ")" );
    			info.parent().css( "background-position", "center" );
    			info.parent().css( "background-size", "cover" );
    	}

		var splide = new Splide( '.carousel-hero', {
			type: 'loop',
			speed: 500,
			arrows: false,
			autoplay: true,
			interval: 6000,
			waitForTransition: true,
			pauseOnHover: false,
			pauseOnFocus: false,
			start: Math.floor( Math.random() * $( '.carousel-hero .splide__slide' ).length ),
		} );

    	splide.on( 'mounted', function() {} );
    	splide.on( 'moved', function( newIndex, prevIndex, destIndex ) {
    		var $ref = $( splide.Components.Elements.slides[ newIndex ] ).find( '.img-info .title' );
    		gsap.to( $ref.find( '.header' ), {
				duration: 1, 
				scrambleText: {
					text: "#268ToneProud", 
					chars: "upperCase", 
					revealDelay: 0.4, 
					speed: 0.3, 
				}
			});
    		gsap.to( $ref.find( '.sub-header' ), {
				duration: 1.5, 
				// delay: 0.5,
				scrambleText: {
					text: "CAPTURE YOUR UNIQUE TONE", 
					chars: "upperCase", 
					revealDelay: 0.4, 
					speed: 0.3, 
				}
			});
    	} );
    	splide.mount();

    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */
    	/* SECTION 2 TIMELINE ------------------------------- */
    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */

		var $ref = $( '.section-2 .header .content-right' );
			$ref.data( 'txt' ) == undefined ? $ref.data( 'txt', $ref.text() ): '';
		var st_2 = new SplitText( $ref, { type: "chars,words" } );

		var arr = $( '.section-2 .video-holder .img-holder img' ).eq( 0 ).attr( "srcset" ).split( "," );
		var tgt = new URL( arr[ 0 ].split( " " )[ 0 ] );
		$( '.section-2 .video-holder .img-holder' ).css( "background-image", "url(" + tgt.href + ")" );
		$( '.section-2 .video-holder .img-holder' ).css( "background-position", "center" );
		$( '.section-2 .video-holder .img-holder' ).css( "background-size", "cover" );

		let mm_2 = gsap.matchMedia();
		let mm_2_breakPoint = 768;

		mm_2.add({
			// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
			isDesktop: `(min-width: ${mm_2_breakPoint}px) and (prefers-reduced-motion: no-preference)`,
			isMobile: `(max-width: ${mm_2_breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
		}, (context) => {
			// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
			let { isDesktop, isMobile } = context.conditions;

			let t2_1 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-2 .header .content-left',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '25': '50' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});
			
			let t2_2 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-2 .header .content-right',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '25': '25' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			let t2_3 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-2 .video-holder',
			        start: ( isDesktop ? 'top': 'center-=25%' ) + ' 50%',
			        end: ( isDesktop ? 'center': 'center' ) + ' 50%',
			        // end: '+=' + ( isDesktop ? '25': '50' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			t2_1.addLabel( 't2_1-start' )
				.from( '.section-2 .header .content-left', { autoAlpha: 0, ease: "power2.out" }, 't2_1-start')
				.from( '.section-2 .header .content-left', { xPercent: -10, ease: "power1.out" }, 't2_1-start')
			  	.addLabel('t2_1-end');

			t2_2.addLabel( 't2_2-start' )
			  	.from( st_2.words, {
					// duration: 1,
					opacity: 0,
					transformOrigin: "0% 50% -50",
					translateX: "10px",
					ease: "power1.inOut",
					stagger: 0.1
			  	}, 't2_2-start' )
			  	.addLabel('t2_2-end');

			t2_3.addLabel( 't2_3-start' )
				.from( '.section-2 .video-holder .info .msg', { autoAlpha: 0, translateY: ( isDesktop ? '': '-' ) + '20px', ease: "power1.inOut", delay: ( isDesktop ? '0.0': '0.5' ) }, 't2_3-start' )
				.from( '.section-2 .video-holder .info .title', { autoAlpha: 0, translateY: ( isDesktop ? '': '-' ) + '20px', ease: "power1.inOut", delay: ( isDesktop ? '0.25': '0.25' ) }, 't2_3-start' )
				.from( '.section-2 .video-holder .info .cta', { autoAlpha: 0, translateY: ( isDesktop ? '': '-' ) + '20px', ease: "power1.inOut", delay: ( isDesktop ? '0.5': '0.0' ) }, 't2_3-start' )
			  	.addLabel('t2_3-end');

			return () => { 
				// optionally return a cleanup function that will be called when the media query no longer matches
			}
		});

		let t2_pin = gsap.timeline({
		    // yes, we can add it to an entire timeline!
		    scrollTrigger: {
		        trigger: '.section-2',
		        endTrigger: ".section-2 .video-holder",
		        start: 'top 0%',
		        end: 'top 0%',
		        // end: () => 'top+=' + ( $( '.section-2 .video-holder' ).height() ) + ' 0%',
		        scrub: 1,
		        pin: '.section-2-pin',
		        pinSpacing: false,
		        // markers: true,
		    }
		});


    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */
    	/* SECTION 3 TIMELINE ------------------------------- */
    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */

		let t3 = gsap.timeline({
		    // yes, we can add it to an entire timeline!
		    scrollTrigger: {
		        trigger: '.section-3',
		        start: 'top 75%', // when the top of the trigger hits the top of the viewport
		        end: '+=50%', // end after scrolling 500px beyond the start
		        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
		        // markers: true,
		    }
		});

		// add animations and labels to the timeline
		t3.addLabel( 't3-start' )
		  .from( '.section-3 .header .msg', { autoAlpha: 0, ease: "power2.out" }, 't3-start')
		  .from( '.section-3 .header .msg', { translateY: '-20px', scale: 0.95, ease: "power1.out" }, 't3-start')
		  .addLabel('t3-end');

		var $ref = $( '.section-3 .content-left .content-left-container .info' );
			$ref.data( 'txt' ) == undefined ? $ref.data( 'txt', $ref.text() ): '';
		var st_3 = new SplitText( $ref, { type: "chars,words" } );

		let mm_3 = gsap.matchMedia();
		let mm_3_breakPoint = 768;

		mm_3.add({
			// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
			isDesktop: `(min-width: ${mm_3_breakPoint}px) and (prefers-reduced-motion: no-preference)`,
			isMobile: `(max-width: ${mm_3_breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
		}, (context) => {
			// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
			let { isDesktop, isMobile } = context.conditions;

			let t3_1 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-3 .content-right',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '25': '25' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			let t3_2 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-3 .content-left',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '25': '25' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			let t3_3 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-3 .card .content-left',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '50': '50' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});
			
			let t3_4 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-3 .card .content-right',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '50': '50' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			t3_1.addLabel( 't3_1-start' )
			  .from( '.section-3 .content-right', { autoAlpha: 0, ease: "power2.out" }, 't3_1-start')
			  .from( '.section-3 .content-right', { xPercent: 10, ease: "power1.out" }, 't3_1-start')
			  .addLabel('t3_1-end');

			t3_2.addLabel( 't3_2-start' )
			  	.from( st_3.words, {
					// duration: 1,
					opacity: 0,
					transformOrigin: "0% 50%",
					translateX: "10px",
					ease: "power1.inOut",
					stagger: 0.1
			  	}, 't3_2-start')
			  	.addLabel('t3_2-end');

			t3_3.addLabel( 't3_3-start' )
				.from( '.section-3 .card .content-left .img-holder', { translateY: "20px", ease: "power2.out" }, 't3_3-start')
				.from( '.section-3 .card .content-left .info', { autoAlpha: 0, translateY: '20px', ease: "power1.out" })
			  	.addLabel('t3_3-end');

			t3_4.addLabel( 't3_4-start' )
				.from( '.section-3 .card .content-right .img-holder', { translateY: "20px", ease: "power2.out" }, 't3_4-start')
				.from( '.section-3 .card .content-right .info', { autoAlpha: 0, translateY: '20px', ease: "power1.out" })
			  	.addLabel('t3_4-end');

			return () => { 
				// optionally return a cleanup function that will be called when the media query no longer matches
			}
		});

    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */
    	/* SECTION 4 TIMELINE ------------------------------- */
    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */

		/* SETUP THE CAROUSEL */
		var splide_details = new Splide( '.carousel-details', {
			// type: 'loop',
			speed: 500,
			arrows: false,
			waitForTransition: true,
			perPage: 3,
			gap: '4rem',
			drag: false,
			breakpoints: {
				767: {
					perPage: 1,
					gap: '1rem',
					pagination: false,
					fixedWidth: '95%',
					padding: { left: '1.5rem', right: '1.5rem' },
					drag: true,
				},
			},
		} );

    	splide_details.on( 'mounted', function() {} );
    	splide_details.on( 'moved', function( newIndex, prevIndex, destIndex ) { } );
    	splide_details.mount();

		let mm_4 = gsap.matchMedia();
		let mm_4_breakPoint = 768;

		mm_4.add({
			// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
			isDesktop: `(min-width: ${mm_4_breakPoint}px) and (prefers-reduced-motion: no-preference)`,
			isMobile: `(max-width: ${mm_4_breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
		}, (context) => {
			// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
			let { isDesktop, isMobile } = context.conditions;

			let t4_1 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-4 .carousel-details',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '25': '25' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			if( isDesktop )
			{
				t4_1.addLabel( 't4_1-start' )
				  .from( '.section-4 .carousel-details .content', { autoAlpha: 0, ease: "power2.out", stagger: 0.1 }, 't4_1-start')
				  .from( '.section-4 .carousel-details .content', { yPercent: 10, ease: "power1.out",stagger: 0.1 }, 't4_1-start')
				  .addLabel('t4_1-end');
			}
			else
			{
				t4_1.addLabel( 't4_1-start' )
				  .from( '.section-4 .carousel-details', { autoAlpha: 0, ease: "power2.out" }, 't4_1-start')
				  .from( '.section-4 .carousel-details', { yPercent: 10, ease: "power1.out" }, 't4_1-start')
				  .addLabel('t4_1-end');
			}


			return () => { 
				// optionally return a cleanup function that will be called when the media query no longer matches
			}
		});

    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */
    	/* SECTION 5 TIMELINE ------------------------------- */
    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */

		var $ref = $( '.section-5 .header .content-left .txt' );
			$ref.data( 'txt' ) == undefined ? $ref.data( 'txt', $ref.text() ): '';
		var st_5 = new SplitText( $ref, { type: "chars,words" } );

		let mm_5 = gsap.matchMedia();
		let mm_5_breakPoint = 768;

		mm_5.add({
			// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
			isDesktop: `(min-width: ${mm_5_breakPoint}px) and (prefers-reduced-motion: no-preference)`,
			isMobile: `(max-width: ${mm_5_breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
		}, (context) => {
			// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
			let { isDesktop, isMobile } = context.conditions;

			let t5_1 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-5 .header .content-left',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '25': '25' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			let t5_2 = gsap.timeline({
			    // yes, we can add it to an entire timeline!
			    scrollTrigger: {
			        trigger: '.section-5 .gallery-holder',
			        start: 'top 75%',
			        end: isDesktop ? '+=25%': 'bottom 75%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			t5_1.addLabel( 't5_1-start' )
			  	.from( st_5.words, {
					// duration: 1,
					opacity: 0,
					transformOrigin: "0% 50%",
					translateX: "10px",
					ease: "power1.inOut",
					stagger: 0.1
			  	}, 't5_1-start')
			  	.addLabel('t5_1-end');

			t5_2.addLabel( 't5_2-start' )
			  .from( '.section-5 .gallery' + ( isMobile ? ' .content': '' ), { autoAlpha: 0, translateY: isMobile ? 0: 20, scale: isMobile ? 0.9: 1, transformOrigin: "50% 50%", stagger: ( isMobile ? 0.1: 0 ), ease: "power2.out" })
			  .addLabel('t5_2-end');

			return () => { 
				// optionally return a cleanup function that will be called when the media query no longer matches
			}
		});
	});

    
})( 'body[data-section="home"]' );

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- WEBAPP ------------------------------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

( function( selector )
{
	'use strict'

    var scope = $( selector );
    if( !scope.length ) return;

	document.addEventListener( 'alpine:init', () => {
	    Alpine.data( 'videoError', () => ({
	        error: false,
			init() {
				this.$watch( 'error', () => {
					$( '.face-api-info' )[ this.error ? 'addClass': 'removeClass' ]( 'error' );
					// gsap.to( "#smooth-wrapper", { filter: "blur(" + (this.open ? '10px': '0px') + ")", ease: "power2.in", duration: 0.3 });
				})
			}
	    }));
	});

	window.videoError_true = function ( txt ) { var ref = document.getElementById( 'videoError' )._x_dataStack[ 0 ]; ref.error = true; $( '.face-api-info .text' ).text( txt ); }
	window.videoError_false = function ( txt ) { var ref = document.getElementById( 'videoError' )._x_dataStack[ 0 ]; ref.error = false; $( '.face-api-info .text' ).text( txt ); }

	Alpine.start();

    // async function requestExternalImage(imageUrl) {
    // 	const res = await fetch('fetch_external_image', {
    // 		method: 'post',
    // 		headers: {
    // 			'content-type': 'application/json'
    // 		},
    // 		body: JSON.stringify({ imageUrl })
    // 	})
    // 	if (!(res.status < 400)) {
    // 		console.error(res.status + ' : ' + await res.text())
    // 		throw new Error('failed to fetch image from url: ' + imageUrl)
    // 	}

    // 	let blob
    // 	try {
    // 		blob = await res.blob()
    // 		return await faceapi.bufferToImage(blob)
    // 	} catch (e) {
    // 		console.error('received blob:', blob)
    // 		console.error('error:', e)
    // 		throw new Error('failed to load image from url: ' + imageUrl)
    // 	}
    // }

    const MODEL_URL = 'face-api-models';
    const SSD_MOBILENETV1 = 'ssd_mobilenetv1';
    const TINY_FACE_DETECTOR = 'tiny_face_detector';

    let selectedFaceDetector = SSD_MOBILENETV1;

	// ssd_mobilenetv1 options
    let minConfidence = 0.5;

	// tiny_face_detector options
    let inputSize = 512;
    let scoreThreshold = 0.5;
    let recordedResult = undefined;

	function rgb2hsv( r, g, b )
	{
	    let rabs, gabs, babs, rr, gg, bb, h, s, v, diff, diffc, percentRoundFn;
	    rabs = r / 255;
	    gabs = g / 255;
	    babs = b / 255;
	    v = Math.max(rabs, gabs, babs),
	        diff = v - Math.min(rabs, gabs, babs);
	    diffc = c => (v - c) / 6 / diff + 1 / 2;
	    percentRoundFn = num => Math.round(num * 100) / 100;

	    if( diff == 0 ) h = s = 0;
	    else
	    {
	        s = diff / v;
	        rr = diffc( rabs );
	        gg = diffc( gabs );
	        bb = diffc( babs );

	        if( rabs === v ) h = bb - gg;
	        else if( gabs === v ) h = (1 / 3) + rr - bb;
	        else if( babs === v ) h = (2 / 3) + gg - rr;

	        if( h < 0 ) h += 1;
	        else if( h > 1 ) h -= 1;
	    }

	    return {
	        h: Math.round( h * 360 ),
	        s: percentRoundFn( s * 100 ),
	        v: percentRoundFn( v * 100 )
	    };
	}


	function filterSkin( data )
	{

	    for( var i = 0; i < data.length; i += 4 )
	    {
	        var result = rgb2hsv( data[ i ], data[ i + 1 ], data[ i + 2 ] );

	        if( !( ( ( 0.0 <= result.h && result.h <= 50.0 ) ) && 23 <= result.s && result.s <= 68  &&
	            data[ i ] > 95 && data[ i + 1 ] > 40 && data[ i + 2 ] > 20 && data[ i ] > data[ i + 1 ] &&
	            data[ i ] > data[ i + 2 ] && ( data[ i ] - data[ i + 1 ] ) > 15 && data[ i + 3 ] > 15 ) )
	        {

	            data[ i ] = 0;
	            data[ i + 1 ] = 0;
	            data[ i + 2 ] = 0;
	        }
	    }

	    return data;
	}

	const buildRgb = (imageData) => {
		const rgbValues = [];
		// note that we are loopin every 4!
		// for every Red, Green, Blue and Alpha
		for (let i = 0; i < imageData.length; i += 4) {
			const rgb = {
				r: imageData[i],
				g: imageData[i + 1],
				b: imageData[i + 2],
			};

			rgbValues.push(rgb);
		}

		return rgbValues;
	};

	// Convert each pixel value ( number ) to hexadecimal ( string ) with base 16
	const rgbToHex = (pixel) => {
		const componentToHex = (c) => {
			const hex = c.toString(16);
			return hex.length == 1 ? "0" + hex : hex;
		};

		return (
			"#" +
			componentToHex(pixel.r) +
			componentToHex(pixel.g) +
			componentToHex(pixel.b)
			).toUpperCase();
	};

	/**
	 * Convert HSL to Hex
	 * this entire formula can be found in stackoverflow, credits to @icl7126 !!!
	 * https://stackoverflow.com/a/44134328/17150245
	 */
	const hslToHex = (hslColor) => {
		const hslColorCopy = { ...hslColor };
		hslColorCopy.l /= 100;
		const a =
		(hslColorCopy.s * Math.min(hslColorCopy.l, 1 - hslColorCopy.l)) / 100;
		const f = (n) => {
			const k = (n + hslColorCopy.h / 30) % 12;
			const color = hslColorCopy.l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
			return Math.round(255 * color)
			.toString(16)
			.padStart(2, "0");
		};
		return `#${f(0)}${f(8)}${f(4)}`.toUpperCase();
	};

	/**
	 * Convert RGB values to HSL
	 * This formula can be
	 * found here https://www.niwa.nu/2013/05/math-behind-colorspace-conversions-rgb-hsl/
	 */
	const convertRGBtoHSL = (rgbValues) => {
		return rgbValues.map((pixel) => {
			let hue,
			saturation,
			luminance = 0;

    		// first change range from 0-255 to 0 - 1
			let redOpposite = pixel.r / 255;
			let greenOpposite = pixel.g / 255;
			let blueOpposite = pixel.b / 255;

			const Cmax = Math.max(redOpposite, greenOpposite, blueOpposite);
			const Cmin = Math.min(redOpposite, greenOpposite, blueOpposite);

			const difference = Cmax - Cmin;

			luminance = (Cmax + Cmin) / 2.0;

			if (luminance <= 0.5) {
				saturation = difference / (Cmax + Cmin);
			} else if (luminance >= 0.5) {
				saturation = difference / (2.0 - Cmax - Cmin);
			}

		    /**
		     * If Red is max, then Hue = (G-B)/(max-min)
		     * If Green is max, then Hue = 2.0 + (B-R)/(max-min)
		     * If Blue is max, then Hue = 4.0 + (R-G)/(max-min)
		     */
			const maxColorValue = Math.max(pixel.r, pixel.g, pixel.b);

			if (maxColorValue === pixel.r) {
				hue = (greenOpposite - blueOpposite) / difference;
			} else if (maxColorValue === pixel.g) {
				hue = 2.0 + (blueOpposite - redOpposite) / difference;
			} else {
				hue = 4.0 + (greenOpposite - blueOpposite) / difference;
			}

		    hue = hue * 60; // find the sector of 60 degrees to which the color belongs

		    // it should be always a positive angle
		    if (hue < 0) {
		    	hue = hue + 360;
		    }

		    // When all three of R, G and B are equal, we get a neutral color: white, grey or black.
		    if (difference === 0) {
		    	return false;
		    }

		    return {
				h: Math.round(hue) + 180, // plus 180 degrees because that is the complementary color
				s: parseFloat(saturation * 100).toFixed(2),
				l: parseFloat(luminance * 100).toFixed(2),
			};
		});
	};

	/**
	* Calculate the color distance or difference between 2 colors
	*
	* further explanation of this topic
	* can be found here -> https://en.wikipedia.org/wiki/Euclidean_distance
	* note: this method is not accuarate for better results use Delta-E distance metric.
	*/
	const calculateColorDifference = (color1, color2) => {
		const rDifference = Math.pow(color2.r - color1.r, 2);
		const gDifference = Math.pow(color2.g - color1.g, 2);
		const bDifference = Math.pow(color2.b - color1.b, 2);

		return rDifference + gDifference + bDifference;
	};

	// returns what color channel has the biggest difference
	const findBiggestColorRange = (rgbValues) => {
		/**
		* Min is initialized to the maximum value posible
		* from there we procced to find the minimum value for that color channel
		*
		* Max is initialized to the minimum value posible
		* from there we procced to fin the maximum value for that color channel
		*/
		let rMin = Number.MAX_VALUE;
		let gMin = Number.MAX_VALUE;
		let bMin = Number.MAX_VALUE;

		let rMax = Number.MIN_VALUE;
		let gMax = Number.MIN_VALUE;
		let bMax = Number.MIN_VALUE;

		rgbValues.forEach((pixel) => {
			rMin = Math.min(rMin, pixel.r);
			gMin = Math.min(gMin, pixel.g);
			bMin = Math.min(bMin, pixel.b);

			rMax = Math.max(rMax, pixel.r);
			gMax = Math.max(gMax, pixel.g);
			bMax = Math.max(bMax, pixel.b);
		});

		const rRange = rMax - rMin;
		const gRange = gMax - gMin;
		const bRange = bMax - bMin;

// determine which color has the biggest difference
		const biggestRange = Math.max(rRange, gRange, bRange);
		if (biggestRange === rRange) {
			return "r";
		} else if (biggestRange === gRange) {
			return "g";
		} else {
			return "b";
		}
	};

	/**
	 * Median cut implementation
	 * can be found here -> https://en.wikipedia.org/wiki/Median_cut
	 */
	const quantization = (rgbValues, depth) => {
		const MAX_DEPTH = 4;

		// Base case
		if (depth === MAX_DEPTH || rgbValues.length === 0) {
			const color = rgbValues.reduce(
				(prev, curr) => {
					prev.r += curr.r;
					prev.g += curr.g;
					prev.b += curr.b;

					return prev;
				},
				{
					r: 0,
					g: 0,
					b: 0,
				}
				);

			color.r = Math.round(color.r / rgbValues.length);
			color.g = Math.round(color.g / rgbValues.length);
			color.b = Math.round(color.b / rgbValues.length);

			return [color];
		}

		/**
		*  Recursively do the following:
		*  1. Find the pixel channel (red,green or blue) with biggest difference/range
		*  2. Order by this channel
		*  3. Divide in half the rgb colors list
		*  4. Repeat process again, until desired depth or base case
		*/
		const componentToSortBy = findBiggestColorRange(rgbValues);
		rgbValues.sort((p1, p2) => {
			return p1[componentToSortBy] - p2[componentToSortBy];
		});

		const mid = rgbValues.length / 2;
		return [
			...quantization(rgbValues.slice(0, mid), depth + 1),
			...quantization(rgbValues.slice(mid + 1), depth + 1),
			];
	};

	var averageRGB = (function () {

		// Keep helper stuff in closures
		var reSegment = /[\da-z]{2}/gi;

		// If speed matters, put these in for loop below
		function dec2hex(v) {return v.toString(16);}
		function hex2dec(v) {return parseInt(v,16);}

		return function (c1, c2) {

			// Split into parts
			var b1 = c1.match(reSegment);
			var b2 = c2.match(reSegment);
			var t, c = [];

			// Average each set of hex numbers going via dec always rounds down
			for (var i=b1.length; i;) {
				t = dec2hex( (hex2dec(b1[--i]) + hex2dec(b2[i])) >> 1 );

			// Add leading zero if only one character
				c[i] = t.length == 2? '' + t : '0' + t; 
			}
			return  c.join('');
		}
	}());

    function getFaceDetectorOptions()
    {
    	return selectedFaceDetector === SSD_MOBILENETV1
    	? new faceapi.SsdMobilenetv1Options({ minConfidence })
    	: new faceapi.TinyFaceDetectorOptions({ inputSize, scoreThreshold });
    }

    function getCurrentFaceDetectionNet()
    {
    	if (selectedFaceDetector === SSD_MOBILENETV1) {
    		return faceapi.nets.ssdMobilenetv1;
    	}
    	if (selectedFaceDetector === TINY_FACE_DETECTOR) {
    		return faceapi.nets.tinyFaceDetector;
    	}
    }

    function isFaceDetectionModelLoaded()
    {
    	return !!getCurrentFaceDetectionNet().params;
    }

    async function changeFaceDetector(detector)
    {
    	selectedFaceDetector = detector
    	if (!isFaceDetectionModelLoaded()) {
    		await getCurrentFaceDetectionNet().load( MODEL_URL );
    	}
    }

    async function trackVideoAction( capture = false )
    {
    	const video = $( '#faceAPIVideo' ).get( 0 );

    	if( ( !capture && video.paused ) || ( !capture && video.ended ) || !isFaceDetectionModelLoaded() )
    	{
    		videoError_true( 'API NOT READY' );
    		return capture ? false: setTimeout( () => trackVideoAction() );
    	}

    	const options = getFaceDetectorOptions();
    	const result = await faceapi.detectAllFaces( video, options );

    	if( result )
    	{
    		switch( result.length )
    		{
    			case 0: videoError_true( 'NO FACES DETECTED' ); break;
    			case 1: videoError_false( capture ? 'CAPTURED': 'READY' );
    				if( capture )
    				{
			    		const canvas = $( '#faceAPIOverlay' ).get( 0 );
			    		const dims = faceapi.matchDimensions( canvas, video, true );
					    const ctx = canvas.getContext( '2d' );
							  ctx.drawImage( video, 0, 0, canvas.width, canvas.height );
						recordedResult = {
							image: canvas.toDataURL( 'image/jpeg' ),
							result: result[ 0 ],
						};
			    		faceapi.draw.drawDetections( canvas, faceapi.resizeResults( result[ 0 ], dims ) );
    				}
    				break;
    			default: videoError_true( 'MULTIPLE FACES DETECTED' ); break;
    		}
    	}
    	else
		{
			videoError_true( 'NO FACES DETECTED' );
		}

    	if( !capture ) setTimeout( () => trackVideoAction() );
    }
    window.trackVideoAction = trackVideoAction;

	async function snap_action( e )
    {
    	await trackVideoAction( true );
	
		if( recordedResult )
		{
    		let canvas = $( '#faceAPIOverlay' ).get( 0 );
			let canvas_result = $( '#resultOverlay' ).get( 0 );
				canvas_result.width  = recordedResult.result.box.width;
				canvas_result.height = recordedResult.result.box.height;
			let ctx = canvas_result.getContext( '2d' );
			let img = new Image;
				img.onload = function(){
					ctx.drawImage(
						img,
						recordedResult.result.box.left,
						recordedResult.result.box.top,
						recordedResult.result.box.width,
						recordedResult.result.box.height,
						0, 0, recordedResult.result.box.width, recordedResult.result.box.height
					);

					let extract_data = ctx.getImageData( 0, 0, recordedResult.result.box.width, recordedResult.result.box.height );
					let extract_data_filtered = new ImageData( filterSkin( extract_data.data ), extract_data.width, extract_data.height, { colorSpace: extract_data.colorSpace } );
					
					let extract_result = $( '#extractOverlay' ).get( 0 );
						extract_result.width  = extract_data_filtered.width;
						extract_result.height = extract_data_filtered.height;
					let extract_ctx = extract_result.getContext( '2d' );
						extract_ctx.putImageData( extract_data_filtered, 0, 0 );

					/**
					* getImageData returns an array full of RGBA values
					* each pixel consists of four values: the red value of the colour, the green, the blue and the alpha
					* (transparency). For array value consistency reasons,
					* the alpha is not from 0 to 1 like it is in the RGBA of CSS, but from 0 to 255.
					*/
					const imageData = extract_ctx.getImageData( 0, 0, extract_data_filtered.width, extract_data_filtered.height );

					// Convert the image data to RGB values so its much simpler
					const rgbArray = buildRgb(imageData.data);

					/**
					* Color quantization
					* A process that reduces the number of colors used in an image
					* while trying to visually maintin the original image as much as possible
					*/
					let quantColors = quantization(rgbArray, 0);

					let avgColor = undefined;

					for (var i = 0; i < quantColors.length; i++) {
						let val = rgbToHex( quantColors[ i ] );
						if( val != "#000000" )
						{
							if( avgColor == undefined ) avgColor = val;
							else avgColor = averageRGB( avgColor, val );
						}
					}

					if( avgColor != undefined )
					{	
						let tone_result = $( '#toneOverlay' ).get( 0 );
							tone_result.width  = extract_data_filtered.width;
							tone_result.height = extract_data_filtered.height;
						let tone_ctx = tone_result.getContext( '2d' );
							tone_ctx.fillStyle = "#" + avgColor;
							tone_ctx.fillRect( 0, 0, tone_result.width, tone_result.height);
					}
				};

			img.src = recordedResult.image; // binary data
		}
		else
		{
			videoError_true( 'NO FACES DETECTED' );
		}
    }

    async function runCamera()
    {
		if( !navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices )
		{
		    console.log("enumerateDevices is not supported.");
		    return false;
		}

		// load face detection model
    	await changeFaceDetector( TINY_FACE_DETECTOR );
    	// await changeFaceDetector(SSD_MOBILENETV1); // WEIRD ERROR

		// try to access users webcam and stream the images to the video element
    	const stream = await navigator.mediaDevices.getUserMedia( { video: {}, audio: false } );
    	const videoEl = $( '#faceAPIVideo' ).get( 0 );
    	videoEl.srcObject = stream;

    	return videoEl;
    }

	$( document ).ready( function()
	{
		let camera = runCamera();

		if( camera ) $( 'button.btn-snap' ).on( 'click', snap_action );
    })

    
})( 'body[data-section="webapp"]' );





